import {Pipe, PipeTransform, Injectable} from '@angular/core';

@Pipe({
    name: 'wallet',
    standalone: true,
})
export class WalletPipe implements PipeTransform {
    transform(items: any[], wallet_code:string = 'KRW'): any {
        if (!items) return null;

        const k = items.find(it => it['wallet_code'] === wallet_code);
        return k ? k['balance'] : null;
    }
}